/*
 * 업무구분: 고객
 * 화 면 명: MSPCM212M
 * 화면설명: 그룹상세
 * 작 성 일: 2023.04.04
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp txtSkip" :title="lv_Title" :show-title="true" type="subpage" @on-header-left-click="fn_HistoryBack" :action-type="!isSearched? actionSlot: ''"
   :topButton="true" :topButtonBottom="topButtonBottom">
    <!-- 전체체크 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox size="small" v-model="isOneOrMoreCheck" @input="fn_CheckAll()" :disabled="checkDisabled || isEmptyView" />
          <div class="list-item__contents fexTy3">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{totCnt}}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong> 명 선택</span>
            </div>
            <mo-button v-if="!isSearched" componentid="" color="blue" shape="hyperlink" size="large" class="ns-btn-round crTy-blue4 pl0 pr0 minWauto" @click="fn_OpenMSPSA203P">고객추가</mo-button>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="" direction="column" class="ns-check-list">  
        <mo-list :list-data="cmICCstgrRltnVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItem(item)" :disabled="checkDisabled || isEmptyView" :ref="`chk${index}`"/>
              <div class="list-item__contents">
                <!-- 고객명, 고객구분 -->
                <div class="list-item__contents__title">
                  <span class="name txtSkip" @click.stop="fn_OpenCustInfoBs(item)">{{item.custNm}}</span>
                  <mo-badge class="badge-sample-badge lightblue sm" :text="item.chnlCustScCdNm" shape="status">{{item.chnlCustScCdNm}}</mo-badge> 
                </div>
                <!-- 생년월일, 성별 -->
                <div class="list-item__contents__info">
                  <span>{{$commonUtil.dateDashFormat($bizUtil.getRrnToBirthDay(item.rrnFrnoNo))}}</span><em>|</em><span><span>{{item.sxdsCdNm}}</span></span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list> 
      </ur-box-container>

      <!-- NoData 영역  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <!-- 확인 메시지 -->
      <mo-bottom-sheet ref="bottomSheetMsg1"  class="ns-bottom-sheet">
        <div class="customer-info-txt txt-center">
          <p class="ment">그룹을 삭제하시겠습니까?</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_InitBottomSheet" name="취소">취소</mo-button>
              <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SubmitD1" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <mo-bottom-sheet ref="bottomSheetExclu" :close-btn="false" :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_SubmitD" class="bgcolor-2 bd-radius-6 blue">그룹에서 제외</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <mo-bottom-sheet ref="bottomSheetEdit" :close-btn="false" :show="true" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        그룹명 편집
        <div class="ns-btn-close" @click="fn_InitBottomSheet" name="닫기"></div>
      </template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="sch-box">
          <mo-text-field class="form-input-name" underline clearable placeholder="그룹명 입력" v-model="lv_ChnlCstgrNm" maxlength="30" ref="newChnlCstgrNm" :rules="validateRuleRequire"/>
        </ur-box-container>
      </ur-box-container>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_InitBottomSheet('edit')" class="ns-btn-round white">취소</mo-button>
            <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_SubmitP()" :disabled="!validateRslt" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 초회 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>

    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
    <div v-if="cmICCstgrRltnVO.length > 0" class="ns-height40"></div>
    <div v-if="cmICCstgrRltnVO.length > 0" class="ns-height70"></div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPSA203P from '@/ui/sa/MSPSA203P' // 고객검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM212M",
  screenId: "MSPCM212M",
  components: {
    MSPSA203P,
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    let lv_Vm = this

    // 타 화면에서 뒤로가기로 내그룹상세 진입 시
    if (this.$bizUtil.isEmpty(this.$route.params.item)) {
      this.$router.replace({ name: 'MSPCM210M' })
      return
    }

    // 화면 상단 설정
    this.lv_Title = this.$route.params.item.chnlCstgrNm
    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-button @click="fn_OpenBs()" shape="border" class="ns-btn-round btn-title-style-1">그룹명 편집</mo-button><mo-icon icon="msp-trash" @click="fn_SubmitD1()">msp-trash</mo-icon></div>',
        methods: {
          fn_OpenBs () {
            lv_Vm.fn_OpenBs('edit')
          },
          fn_SubmitD1 () {
            lv_Vm.fn_OpenBs('del')
          }
        }
      }
    }

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    if (this.$cmUtil.isSrchOrgCombo() === 'Y') {  // 지점장 여부
      this.isMngr = true
    }
    this.pOrgData210M = this.$route.params.pOrgData210M
    if (!_.isEmpty(this.pOrgData210M)) {  // 조직 데이터 존재 여부
      this.isSearched = true
    }
    this.lv_ChnlCstgrNm = this.$route.params.item.chnlCstgrNm // 기존 그룹명
    this.cmCustGrpListSVO = this.$route.params.pCmCustGrpListSVO  // 기존 그룹 리스트
    // 그룹 상세 조회
    this.fn_Submit()
  },

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  computed: {
    selectItems() {
      return this.cmICCstgrRltnVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cmICCstgrRltnVO.filter(item => item.checked).length
    },
    totCnt () {
      return this.cmICCstgrRltnVO.length
    },
    validateRslt () {
      let val = this.lv_ChnlCstgrNm

      if (this.$bizUtil.isEmpty(val)) {
        this.validateMsg = '그룹명을 입력해주세요.'
        return false
      } else if (this.cmCustGrpListSVO.find(e => e.chnlCstgrNm === val)) {
        this.validateMsg = '동일한 그룹명이 존재합니다.'
        return false
      } else if (this.$bizUtil.getByteLength(val) > 40) {
        this.validateMsg = '그룹명은 한글 20자(영문 30자) 이상 입력할 수 없습니다.'
        return false
      } else return true
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },

  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.fn_InitBottomSheet()
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      validateRuleRequire: [
        (v) => this.validateRslt || this.validateMsg,
      ],

      // 제어 변수
      isOneOrMoreCheck: false,  // 전체선택
      isLoading: false,         // 로딩 여부
      isEmptyView: false,       // 데이터가 없습니다 visible 여부
      positionUp: false,        // 사용자 바텀시트 오픈여부
      isCustCardExist: false,   // 채널고객ID 보유여부
      isMngr: false,            // 지점장 여부
      checkDisabled: false,     // 체크박스 비활성화 여부
      isSearched: false,        // 조직 데이터 존재 여부

      // 데이터 변수
      popup203: {},             // MSPSA071P 고객검색
      pOrgData210M: {},         // 최근 조직 조회 데이터
      userInfo: {},             // 사용자 정보
      lv_Title: '',             // 화면 상단 타이틀
      cmCustGrpListSVO: [],     // 인피니트 바인딩 리스트VO(그룹목록)
      // 서비스호출 파라미터 (그룹 고객 조회)
      lv_inputParamS1: {
        cnsltNo: '',            // 컨설턴트번호
        chnlCstgrId: '',        // 그룹아이디
        chnlCstgrNm: ''         // 그룹명
      },
      stndKeyList: [],
      stndKeyListS1: [],        // 더보기 key
      stndKeyValS1: 'start',
      pageRowCnt: '200',        // 페이지 카운트
      cmICCstgrRltnVO: [],      // 인피니트 바인딩 리스트VO(그룹고객목록)
      // 서비스호출 파라미터 (그룹명 편집)
      lv_ChnlCstgrNm: '',       // 새 그룹명
      lv_inputParamP: {
        cnsltNo: '',            // 컨설턴트번호
        chnlCstgrNm: '',
        chnlCstgrId: '',
        iCCstgrRltnVO: []
      },
      // 서비스호출 파라미터 (그룹 삭제)
      lv_inputParamD1: {
        cnsltNo: '',            // 컨설턴트번호
        iCGrpInfoVO: []
      },
      // 서비스호출 파라미터 (그룹에서 제외)
      lv_inputParamD: {
        cnsltNo: '',            // 컨설턴트번호
        iCCstgrRltnVO: []
      },

      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.'
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (!this.isMngr) {
          this.$router.replace({ name: 'MSPCM210M' })
        } else {
          // 지점장: 타 컨설턴트 조회 시 조직정보 이동
          this.$router.replace(
            { name: 'MSPCM210M', params: {pPage: this.$options.screenId, pOrgData210M: this.pOrgData210M} }
          )
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 그룹 상세 조회
     ******************************************************************************/
    fn_Submit () {
      if (this.isMngr === true && !_.isEmpty(this.pOrgData210M)) { // 지점장이고 최초조회가 아닐때
        if (this.userInfo.userId === this.pOrgData210M.cnslt.key) { // 본인 고객조회시
          this.checkDisabled = false // 활동 가능 하고
        } else { // 타 컨설턴트의 고객 조회시
          this.checkDisabled = true // 활동 불가능 하다
        }
      }

      // 초기화
      this.cmICCstgrRltnVO = []

      //초회 진입 시 다른 progress 타입 표시
      window.vue.getStore('progress').dispatch('NONE')
      this.fn_SetProgressMsgPop(true)

      this.fn_DataSetS1() // 조회 파라미터 세팅
      this.fn_GetServiceS1() // 서비스 호출
    },

    /******************************************************************************
      * Function명 : fn_DataSetS1
      * 설명       : 그룹내 고객 조회전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetS1 () {
      this.lv_inputParamS1.cnsltNo = this.userInfo.userId                    // 컨선턴트번호
      this.lv_inputParamS1.chnlCstgrId = this.$route.params.item.chnlCstgrId // 그룹아이디
      this.lv_inputParamS1.chnlCstgrNm = this.$route.params.item.chnlCstgrNm // 그룹명
      this.stndKeyValS1 = 'start'
    },

    /******************************************************************************
      * Function명 : fn_GetServiceS1
      * 설명       : 서비스 호출(그룹내 고객 목록 조회)
    ******************************************************************************/
    fn_GetServiceS1 () {
      if (this.stndKeyValS1 === 'end') return
      this.stndKeyList = this.stndKeyListS1
      // const trnstId = 'txTSSCM35S2'// 그룹내 고객 목록 조회
      const trnstId = 'txTSSCM48S6'// 그룹내 고객 목록 조회
      const auth = 'S'

      let lv_Vm = this
      let pParams = this.lv_inputParamS1
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.iCCstgrRltnVO
            if (responseList !== null) {
              for (let i = 0; i < responseList.length; i++) {
                responseList[i].checked = false
                lv_Vm.cmICCstgrRltnVO.push(responseList[i])
              }
              // 더보기 키값
              lv_Vm.stndKeyListS1 = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyListS1 === null || lv_Vm.stndKeyListS1[0] === null || lv_Vm.stndKeyListS1[0].stndKeyVal === null || lv_Vm.stndKeyListS1[0].stndKeyVal.trim() === '') {
                // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyValS1 = 'end'
                lv_Vm.isLoading = false
                lv_Vm.fn_SetProgressMsgPop(false)
              } else {
                // 다음페이지 데이터 존재함
                lv_Vm.stndKeyValS1 = 'more'
                lv_Vm.isLoading = false
                lv_Vm.fn_GetServiceS1()
              }
              lv_Vm.isEmptyView = false
            } else {
              lv_Vm.isEmptyView = true
              lv_Vm.isLoading = false
              lv_Vm.fn_SetProgressMsgPop(false)
            }
          } else {
            lv_Vm.isLoading = false
            lv_Vm.isEmptyView = true
            lv_Vm.fn_SetProgressMsgPop(false)
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true
          lv_Vm.isLoading = false
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_SubmitP
      * 설명       : 그룹명 편집 확인 버튼 클릭 이벤트
    ******************************************************************************/
    fn_SubmitP (item) {
      this.fn_DataSetP(item) // 파라미터 세팅
      this.fn_GetServiceP() // 서비스 호출
    },

    /******************************************************************************
      * Function명 : fn_DataSetP
      * 설명       : 저장전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetP (item) {
      this.lv_inputParamP.cnsltNo = this.userInfo.userId // 컨설턴트번호
      this.lv_inputParamP.chnlCstgrNm = this.lv_ChnlCstgrNm // 그룹명
      this.lv_inputParamP.chnlCstgrId = this.$route.params.item.chnlCstgrId // 그룹아이디
      
      this.lv_inputParamP.iCCstgrRltnVO = []
      if (this.$bizUtil.isEmpty(item) || item.length === 0) {
        this.lv_inputParamP.iCCstgrRltnVO = null
      } else {
        for (let i = 0; i < item.length; i++) {
          let saveVO = {}
          saveVO.chnlCustId = item[i].chnlCustId
          saveVO.prcsfCd = 'I'
          this.lv_inputParamP.iCCstgrRltnVO.push(saveVO)
        }
      }
    },

    /******************************************************************************
      * Function명 : fn_GetServiceP
      * 설명       : 서비스 호출(그룹 저장)
    ******************************************************************************/
    fn_GetServiceP () {
      const trnstId = 'txTSSCM35P1'// 그룹저장
      const auth = 'S'

      let lv_Vm = this
      let pParams = this.lv_inputParamP
      let confirm = this.getStore('confirm')
      if (this.isLoading) return

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            lv_Vm.$addSnackbar(lv_Vm.$t('co')['ECOC012']) // 저장되었습니다
            // 바텀시트 초기화
            lv_Vm.fn_InitBottomSheet()
            // 리스트 재조회
            lv_Vm.fn_Submit()
            lv_Vm.lv_Title = lv_Vm.lv_ChnlCstgrNm
            lv_Vm.lv_inputParamP.iCCstgrRltnVO = []
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isLoading = false
        })
    },

    /******************************************************************************
      * Function명 : fn_SubmitD1
      * 설명       : 그룹 삭제 버튼 클릭 이벤트
    ******************************************************************************/
    fn_SubmitD1 () {
      // 그룹 삭제 시 Progress 타입 Full로 재설정
      window.vue.getStore('progress').dispatch('FULL')

      this.fn_DataSetD1()
      this.fn_GetServiceD1()
    },

    /******************************************************************************
      * Function명 : fn_DataSetD1
      * 설명       : 그룹삭제 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetD1 () {
      this.lv_inputParamD1.cnsltNo = this.userInfo.userId // 컨설턴트번호
      this.lv_inputParamD1.iCGrpInfoVO = []
      
      let delVO = {}
      delVO.chnlCstgrId = this.$route.params.item.chnlCstgrId
      this.lv_inputParamD1.iCGrpInfoVO.push(delVO)
    },

    /******************************************************************************
      * Function명 : fn_GetServiceD1
      * 설명       : 서비스 호출(그룹삭제)
    ******************************************************************************/
    fn_GetServiceD1 () {
      const trnstId = 'txTSSCM35D1'// 그룹삭제
      const auth = 'S'

      let lv_Vm = this
      let pParams = this.lv_inputParamD1
      let confirm = this.getStore('confirm')
      if (this.isLoading) return

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            lv_Vm.isLoading = false
            lv_Vm.$addSnackbar(lv_Vm.$t('co')['ECOC013']) // "삭제 되었습니다."
            // 이전 화면으로 이동
            lv_Vm.fn_HistoryBack()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_SubmitD
      * 설명       : 그룹에서 제외 버튼 클릭 이벤트
    ******************************************************************************/
    fn_SubmitD () {
      this.fn_DataSetD()
      this.fn_GetServiceD()
    },

    /******************************************************************************
      * Function명 : fn_DataSetD
      * 설명       : 저장전 고객삭제 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetD () {
      this.lv_inputParamD.cnsltNo = this.userInfo.userId // 컨설턴트번호
      this.lv_inputParamD.chnlCstgrId = this.$route.params.item.chnlCstgrId // 그룹아이디
      this.lv_inputParamD.chnlCstgrNm = this.lv_ChnlCstgrNm // 그룹명
      this.lv_inputParamD.iCCstgrRltnVO = this.selectItems
    },

    /******************************************************************************
      * Function명 : fn_GetServiceD
      * 설명       : 서비스 호출(그룹고객삭제)
    ******************************************************************************/
    fn_GetServiceD () {
      const trnstId = 'txTSSCM35D2'// 고객삭제
      const auth = 'S'

      let lv_Vm = this
      let pParams = this.lv_inputParamD
      let confirm = this.getStore('confirm')
      if (this.isLoading) return

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            lv_Vm.isLoading = false
            lv_Vm.isOneOrMoreCheck = false
            // 바텀시트 초기화
            lv_Vm.fn_InitBottomSheet()
            // 리스트 재조회
            lv_Vm.fn_Submit()
            lv_Vm.$addSnackbar('제외 되었습니다.')
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.cmICCstgrRltnVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.cmICCstgrRltnVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.cmICCstgrRltnVO[n].checked = true
            } else {
              lv_Vm.cmICCstgrRltnVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItem()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItem
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다.
     ******************************************************************************/
    fn_CheckSelectedItem (custInfo) {
      let lv_Vm = this

      // 체크된 값 채널아이디 여부 확인
      if (lv_Vm.cmICCstgrRltnVO.filter(item => (item.checked === true && item.chnlCustId.trim() === '')).length === 0) {
        lv_Vm.isCustCardExist = true
      } else {
        lv_Vm.isCustCardExist = false
      }

      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
      if (lv_Vm.checkCount > 0) {
        this.positionUp = true
        this.$refs.bottomSheetExclu.open()
      } else {
        lv_Vm.fn_InitBottomSheet()
      }
    },

    /******************************************************************************
     * Function명: fn_OpenBs
     * 설명: 바텀시트 오픈
     ******************************************************************************/
    fn_OpenBs (flag) {
      let lv_Vm = this

      if (flag === 'edit') {
        lv_Vm.$refs.bottomSheetEdit.open()
        lv_Vm.$nextTick(() => {
          lv_Vm.$refs.newChnlCstgrNm.focus()
        })
      } else if (flag === 'del') {
        lv_Vm.$refs.bottomSheetMsg1.open()
      }
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet (flag) {
      let lv_Vm = this

      if (flag === 'edit') {
        this.lv_ChnlCstgrNm = this.$route.params.item.chnlCstgrNm // 기존 그룹명 초기화
      }

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetExclu !== undefined) {
          lv_Vm.positionUp = false
          lv_Vm.$refs.bottomSheetExclu.close()
        }
        if (lv_Vm.$refs.bottomSheetEdit !== undefined) {
          lv_Vm.$refs.bottomSheetEdit.close()
        }
        if (lv_Vm.$refs.bottomSheetMsg1 !== undefined) {
          lv_Vm.$refs.bottomSheetMsg1.close()
        }
      }
    },

    /******************************************************************************
     * Function명: fn_OpenMSPSA203P
     * 설명: 고객검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPSA203P () {
      let lv_Vm = this
      let paramData = []
      paramData.push({
        pPage: this.$options.screenId,  // 화면명
        pMember: this.cmICCstgrRltnVO   // 기존 그룹구성원
      })

      this.popup203 = this.$bottomModal.open(MSPSA203P, {
        properties: {
          paramData: paramData
        },
        listeners: {
          popupCloseMSPSA203P: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup203)

            if (!this.$bizUtil.isEmpty(pData) && pData.length > 0) {
              lv_Vm.fn_InitBottomSheet()
              // 그룹 편집 저장 서비스 호출 메소드
              lv_Vm.fn_SubmitP(pData)
            }
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}
      
      if(this.pOrgData210M.cnslt === undefined || this.pOrgData210M.cnslt === null || this.pOrgData210M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData210M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM210M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (!lv_Vm.isMngr) {
                lv_Vm.$router.replace({ name: 'MSPCM210M' })
              } else {
                // 지점장: 타 컨설턴트 조회 시 조직정보 이동
                lv_Vm.$router.replace(
                  { name: 'MSPCM210M', params: {pPage: lv_Vm.$options.screenId, pOrgData210M: lv_Vm.pOrgData210M} }
                )
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },
  }
};
</script>
<style scoped>
</style>